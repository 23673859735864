import * as React from 'react';
import SingleColumnLayout from '../layouts/singleColumnLayout/SingleColumnLayout';
import Grid from '../components/soul/layout/grid/Grid';
import {GridDensity} from '../components/soul/layout/grid/GridDensity';
import GridItem from '../components/soul/layout/grid/GridItem';
import {GridItemSize} from '../components/soul/layout/grid/GridItemSize';
import {graphql} from 'gatsby';
import Img, {FluidObject} from 'gatsby-image';
import SimpleReactLightbox, {SRLWrapper} from 'simple-react-lightbox';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface ArchivePageProps {
    data: {
        file: {
            childArchiveJson: {
                title: string;
                description: string;
                images: FluidImage[];
                eventsHeader: string;
                events: EstecoEventData[];
            }
        }
    }
}

export interface FluidImage {
    childImageSharp: {
        fluid: FluidObject
    }
}

interface EstecoEventData {
    logo: {
        publicURL: string;
    };
    logoAlt: string;
    description: string;
    hasLinks: boolean;
    linksHeader: string;
    links: {
        label: string;
        href: string;
    }[];
}

const ArchivePage: React.FC<ArchivePageProps> = ({data}) => {
    const title = data.file.childArchiveJson.title;
    const description = data.file.childArchiveJson.description;
    const images = data.file.childArchiveJson.images;
    const eventsHeader = data.file.childArchiveJson.eventsHeader;
    const events = data.file.childArchiveJson.events;

    return (
        <SingleColumnLayout>
            <SimpleReactLightbox>
                <div className="centered-column">
                    <h1 className="page-title">{title}</h1>
                    <Grid additionalClasses="soul-space-stack-bottom-2-xl">
                        <GridItem size={GridItemSize.ONE_HALF} additionalClasses="l-palm--one-whole">
                            <p className="soul-content  soul-content--m ">{description}</p>
                        </GridItem>
                    </Grid>

                    <Grid mobileInverse>
                        <GridItem size={GridItemSize.ONE_WHOLE}>
                            <Gallery images={images}/>
                        </GridItem>

                        <GridItem size={GridItemSize.ONE_WHOLE}>
                            <Grid additionalClasses="soul-space-stack-top-3-xl">
                                <GridItem size={GridItemSize.ONE_HALF}
                                          additionalClasses="l-palm--one-whole">
                                    <h2 className="soul-font-size-3-xl  h-text-bold  soul-space-stack-bottom-3-xl">{eventsHeader}</h2>
                                </GridItem>

                                {events.map(event => event.hasLinks
                                    ? <EstecoEventDoubleColumn key={event.logoAlt} event={event}/>
                                    : <EstecoEventSingleColumn key={event.logoAlt} event={event}/>
                                )}
                                <div className="soul-space-stack-bottom-m"/>
                            </Grid>
                        </GridItem>
                    </Grid>
                </div>
            </SimpleReactLightbox>
        </SingleColumnLayout>
    );
};

const Gallery: React.FC<{ images: FluidImage[] }> = ({images}) => {
    // https://github.com/michelecocuccio/simple-react-lightbox#options
    const lightboxOptions = {
        buttons: {
            showAutoplayButton: false,
            showDownloadButton: false,
            showFullscreenButton: false,
            showThumbnailsButton: false
        },
        caption: {
            showCaption: false
        }
    };
    return (
        <SRLWrapper options={lightboxOptions}>
            <Grid density={GridDensity.NARROW} additionalClasses="soul-space-stack-bottom-l  l-palm--one-whole">
                <GridItem size={GridItemSize.ONE_HALF} additionalClasses="l-palm--one-whole  soul-space-stack-bottom-s">
                    <Img className="h-cursor-pointer" fluid={images[0].childImageSharp.fluid}/>
                </GridItem>
                <GridItem size={GridItemSize.ONE_HALF} additionalClasses="l-palm--one-whole  soul-space-stack-bottom-s">
                    <Img className="h-cursor-pointer" fluid={images[1].childImageSharp.fluid}/>
                </GridItem>

                <GridItem size={GridItemSize.ONE_THIRD} additionalClasses="l-palm--one-whole  soul-space-stack-bottom-s">
                    <Img className="h-cursor-pointer" fluid={images[2].childImageSharp.fluid}/>
                </GridItem>
                <GridItem size={GridItemSize.ONE_THIRD} additionalClasses="l-palm--one-whole  soul-space-stack-bottom-s">
                    <Img className="h-cursor-pointer" fluid={images[3].childImageSharp.fluid}/>
                </GridItem>
                <GridItem size={GridItemSize.ONE_THIRD} additionalClasses="l-palm--one-whole  soul-space-stack-bottom-s">
                    <Img className="h-cursor-pointer" fluid={images[4].childImageSharp.fluid}/>
                </GridItem>

                <GridItem size={GridItemSize.TWO_THIRDS} additionalClasses="l-palm--one-whole  soul-space-stack-bottom-s">
                    <Img className="h-cursor-pointer" fluid={images[5].childImageSharp.fluid}/>
                </GridItem>
                <GridItem size={GridItemSize.ONE_THIRD} additionalClasses="l-palm--one-whole  soul-space-stack-bottom-s">
                    <div className="soul-space-stack-bottom-s"><Img className="h-cursor-pointer" fluid={images[6].childImageSharp.fluid}/></div>
                    <div><Img className="h-cursor-pointer" fluid={images[7].childImageSharp.fluid}/></div>
                </GridItem>

                <GridItem size={GridItemSize.ONE_HALF} additionalClasses="l-palm--one-whole  soul-space-stack-bottom-s">
                    <Img className="h-cursor-pointer" fluid={images[8].childImageSharp.fluid}/>
                </GridItem>
                <GridItem size={GridItemSize.ONE_HALF} additionalClasses="l-palm--one-whole  soul-space-stack-bottom-s">
                    <Img className="h-cursor-pointer" fluid={images[9].childImageSharp.fluid}/>
                </GridItem>
            </Grid>
        </SRLWrapper>
    );
};

const EstecoEventDoubleColumn: React.FC<{ event: EstecoEventData }> = ({event}) => (
    <React.Fragment>
        <GridItem size={GridItemSize.ONE_WHOLE}>
            <img src={event.logo.publicURL} alt={event.logoAlt} className="soul-space-stack-bottom-l"/>
        </GridItem>

        <GridItem size={GridItemSize.ONE_HALF} additionalClasses="soul-content  soul-content--m  l-palm--one-whole">
            <p className="soul-space-stack-bottom-l">{event.description}</p>
        </GridItem>

        <GridItem size={GridItemSize.ONE_HALF} additionalClasses="soul-content  soul-content--m  l-palm--one-whole">
            <p className="soul-space-stack-bottom-l">{event.linksHeader}</p>
            {event.links.map(link =>
                <div className="soul-space-stack-bottom-s">
                    <a key={link.href} href={link.href}>{link.label}</a>
                    <br/>
                </div>
            )}
        </GridItem>
        <div className="soul-space-stack-bottom-m"/>
    </React.Fragment>
);

const EstecoEventSingleColumn: React.FC<{ event: EstecoEventData }> = ({event}) => (
    <GridItem size={GridItemSize.ONE_HALF} additionalClasses="l-palm--one-whole">
        <img src={event.logo.publicURL} alt={event.logoAlt}/>
        <p className="soul-content  soul-content--m  soul-space-stack-both-l">{event.description}</p>
    </GridItem>
);

export const pageQuery = graphql`
    query ArchivePageContent {
        file(name: {eq: "archive-content"}) {
            childArchiveJson {
                title
                description
                images {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                eventsHeader
                events {
                    logo {
                        publicURL
                    }
                    logoAlt
                    description
                    hasLinks
                    linksHeader
                    links {
                        label
                        href
                    }
                }
            }
        }
    }
`;

export default ArchivePage;
